import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fragment, useMemo, useState } from 'react';
import { getOperPrice } from '../../Axios/calculationsYr';

const CalculatorPrice = ({rozpodilList, monthList, yearList, lastmonth}) => {

  const [rozpValue, SetRozpValue] = useState(0);
  const [klas, SetKlas] = useState(2);
  const [currentPeriod, SetCurrentPeriod] = useState('');
  const [calcList, SetCalcList] = useState([]);
  const parseLastMonth =  parseInt(lastmonth.split('_')[1]);

function rozpChange(e) {
  SetRozpValue(parseInt(e.target.value));
  parseInt(e.target.value) && getOperPrice(e.target.value, false).then((response)=>{
      const data = response['hydra:member'];
      SetCalcList(data);
  }).catch(err => {
      console.log(err);
  })
}

function handleClas(event) {
    SetKlas(parseInt(event.target.value));
}

function handlePeriod(event) {
    SetCurrentPeriod(event.target.value);
}

const showPrice = useMemo(() => {
    if(!currentPeriod){
        SetCurrentPeriod(lastmonth);
    }
    if (calcList.length > 0) {
      return calcList.filter(calcItem => {
                return calcItem.period === currentPeriod && calcItem.klas === klas;
            })
    }
}, [calcList,currentPeriod,klas, lastmonth])

const calcAll = (price_1, price_2,price_3,price_4) => {
    return (price_1 + price_2 + price_3 + price_4).toFixed(2);
}

const calcPdv = (price) => {
    return (price * 1.2).toFixed(2);
}

  return (
    <section className="green">
        <Container>
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={6} className='h2-green'>
                          <h3>Калькулятор для<br/> розрахунку ціни<br/> електроенергії</h3>
                        </Col>
                        <Col md={6} className='nobg-green'>
                          <p></p>
                        </Col>
                    </Row>
                </Col>
                <Col md={6} className='header-green'>
                    <p>Для непобутових споживачів*</p>
                </Col>
                <Col md={6} className='header-green'>
                    <p>Цей розрахунок застосовується до непобутових споживачів електроенергії із дозволеною потужністю до 50 кВт включно, які мають право на універсальну послугу</p>
                </Col>
                <Col md={6} className='header-green'>
                   <div className="form-left d-flex align-items-center"><span className="number-form">01</span> <span className="number-text">Розрахунковий період</span></div>
                </Col>
                <Col md={6} className='header-green'>
                    <select className="form-select" aria-label="Default select" onChange={handlePeriod} value={currentPeriod}>
                         {yearList.map((year, index)=>{
                           if(index === 0) {
                               return monthList.map((month,ind) => { 
                                    if(parseInt(month.id) <= parseLastMonth) {
                                            return <option key={ind} value={year + '_' + month.id}>{month.id+' '}{month.name}{' '+year}</option>}
                                    return <Fragment key={ind}></Fragment> })}
                            return monthList.map((month,ind)=>{
                                    return <option key={ind} value={year + '_' + month.id}>{month.id+' '}{month.name}{' '+year}</option>
                            })}
                         )}
                    </select>
                </Col>
                <Col md={6} className='header-green'>
                   <div className="form-left d-flex align-items-center"><span className="number-form">02</span> <span className="number-text">Оператор системи розподілу, до мереж якого належить електроустановка</span></div>
                </Col>
                <Col md={6} className='header-green'>
                    <select className="form-select" aria-label="Default select" value={rozpValue} onChange={rozpChange}>
                    <option value='0' defaultValue = '0'> Оберіть оператора системи розподілу</option>
                       {rozpodilList && 
                             rozpodilList.map((rozp)=>{
                                return <option key={rozp.id} value={rozp.id}>{rozp.name}</option>
                             })
                         }
                    </select>
                </Col>
                <Col md={6} className='header-green'>
                   <div className="form-left d-flex align-items-center height0"><span className="number-form-norm">03</span> <span className="number-text">Клас напруги</span></div>
                </Col>
                <Col md={6} className='header-green' onChange={handleClas}>
                    <input type="radio" value={1} name="options-outlined" className="circl" id="btn-check-outlined18" autoComplete="off" />
                    <label className="radiobtn" htmlFor="btn-check-outlined18">1-й клас</label>
                    <input type="radio" value={2} name="options-outlined" className="circl" id="btn-check-outlined19" autoComplete="off" defaultChecked/>
                    <label className="radiobtn" htmlFor="btn-check-outlined19">2-й клас</label>
                </Col>    
            </Row>
            {!!showPrice?.length && 
            <Row className='calculator-yrid'>
                <Row className='calculator-yrid__head'>
                    <Col md={6} className='calculator-yrid__descr'>Складові ціни</Col>
                    <Col md={3} className='pdv'>грн/МВт·год <span>без ПДВ</span></Col>
                    <Col md={3} className='pdv'>грн/МВт·год <span>разом з ПДВ</span></Col>
                </Row>
                <Row className='calculator-yrid__item'>
                    <Col md={6} className='calculator-yrid__descr'>Прогнозована середня закупівельна ціна на електроенергію</Col>
                    <Col md={3} className='calculator-yrid__price'>{showPrice[0].priceOne?.toFixed(2)}</Col>
                    <Col md={3} className='calculator-yrid__price'><b>{calcPdv(showPrice[0].priceOne)}</b></Col>
                </Row>
                <Row className='calculator-yrid__item'>
                    <Col md={6} className='calculator-yrid__descr'>Тариф на послуги з розподілу електричної енергії</Col>
                    <Col md={3} className='calculator-yrid__price'>{showPrice[0].priceTwo?.toFixed(2)}</Col>
                    <Col md={3} className='calculator-yrid__price'><b>{calcPdv(showPrice[0].priceTwo)}</b></Col>
                </Row>
                <Row className='calculator-yrid__item'>
                    <Col md={6} className='calculator-yrid__descr'>Тариф на послуги постачальника універсальних послуг</Col>
                    <Col md={3} className='calculator-yrid__price'>{showPrice[0].priceThree?.toFixed(2)}</Col>
                    <Col md={3} className='calculator-yrid__price'><b>{calcPdv(showPrice[0].priceThree)}</b></Col>
                </Row>
                <Row className='calculator-yrid__item'>
                    <Col md={6} className='calculator-yrid__descr'>Тариф на послуги з передачі електричної енергії</Col>
                    <Col md={3} className='calculator-yrid__price'>{showPrice[0].priceFour?.toFixed(2)}</Col>
                    <Col md={3} className='calculator-yrid__price'><b>{calcPdv(showPrice[0].priceFour)}</b></Col>
                </Row>
                <Row className='calculator-yrid__item all'>
                    <Col md={6} className='calculator-yrid__descr'>Всього</Col>
                    <Col md={3} className='calculator-yrid__price'>{calcAll(showPrice[0].priceOne, showPrice[0].priceTwo, showPrice[0].priceThree, showPrice[0].priceFour)}</Col>
                    <Col md={3} className='calculator-yrid__price'><b>{calcPdv(calcAll(showPrice[0].priceOne, showPrice[0].priceTwo, showPrice[0].priceThree, showPrice[0].priceFour))}</b></Col>
                </Row>
            </Row> }
        </Container>
    </section>
  )
}

export default CalculatorPrice